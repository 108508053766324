<template>
    <b-container class="message-board" v-bind:class="{ open: isOpen }" fluid>
        <b-row class="toolbar">
                <!-- <b-button class="t-btn t-play poppins" variant="link" v-b-modal.help-modal>
                    <span class="d-none d-md-block mr-2">Help</span><BIconQuestion/>
                </b-button> -->
                <b-button class="t-btn t-write poppins" variant="link" v-b-modal.message-modal>
                    <span class="mr-2">Add to scroll</span>
                    <BIconPenFill/>
                </b-button>
                <b-button class="t-btn t-find poppins" variant="link" v-b-modal.find-modal>
                    <span class="mr-2">Find your Words</span>
                    <BIconSearch/>
                </b-button>
            </b-row>
        <div class="feed-container">
            <div class="feed" id="feed" ref="feed">
                <FeedComment 
                    :name="item.data.name"
                    :message="item.data.message"
                    :country="item.data.country"
                    :time="item.data.time"
                    v-for="(item, index) in queue"
                    :key="index"
                    v-bind:class="{ active: item.active }"
                >
                </FeedComment>
            </div>
        </div>
        <b-modal id="message-modal" ref="formModal" size="lg" centered hide-footer>
            <SubmitMessage/>
        </b-modal>
        <b-modal id="find-modal" ref="findModal" size="md" centered hide-footer>
            <b-form @submit="searchMessage" class="t-search">
                <div class="search-label">Enter the Message ID provided when you submitted the entry and enter below.</div>
                <b-form-input
                    placeholder="Enter Message ID"
                    v-model="messageId"
                    class="message-search"
                ></b-form-input>
                <b-button variant="link" type="submit" class="t-submit poppins"><span class="mr-2">Find</span><BIconSearch/></b-button>
            </b-form>
        </b-modal>
    </b-container>
</template>

<script>

import FeedComment from '@/components/Experience/FeedComment.vue';
import SubmitMessage from '@/components/Experience/SubmitMessage.vue';
import { getMessages, getMessage } from '@/assets/js/database.js';
import { 
    BIconSearch,
    BIconPenFill 
} from 'bootstrap-vue';
import anime from 'animejs/lib/anime.es.js';

export default {
    name: 'MessageBoard',
    components: {
        FeedComment,
        SubmitMessage,
        BIconSearch,
        BIconPenFill
    },
    data() {
        return {
            messages: [],
            queue: [],
            isOpen: true,
            animation: null,
            formOpen: false,
            messageId: null,
        }
    },
    mounted() {
        this.$nextTick(async function () {

            this.messages = await getMessages();

            this.queue = this.messages;

            this.queue = this.queue.concat(this.messages);

            setTimeout(() => {
                this.messageFeedController();
            }, 2000);

        });
    },
    methods: {
        messageFeedController: async function() {

            await this.messageAnimation();

            if(this.queue.length < this.messages.length) {
                this.queue = this.queue.concat(this.messages);
            }

            this.messageFeedController();
        },
        messageAnimation: async function() {

            let x = (this.$refs.feed.firstChild.offsetHeight + 60) * -1;

            this.animation = anime({
                targets: this.$refs.feed.firstChild,
                marginTop: [0, x],
                duration: 10000,
                easing: 'easeInOutQuad',
            }).finished;

            await Promise.all([this.animation]);

            this.queue.shift();

            return;

        },
        searchMessage: async function(e) {
            e.preventDefault();
            if(this.messageId) {

                const message = await getMessage(this.messageId);

                if(!message) {
                    alert("Sorry, we couldn't find your message.");
                }
                else {
                    this.queue.splice(3, 0, message);
                    this.toggleModal(1);
                }
            }
            else {
                alert('Please enter your Message ID');
            }
        },
        toggleModal: function(index) {
            if(index === 0) {
                this.$refs.formModal.show();
            }
            if(index === 1) {
                this.$refs.findModal.hide();
            }
        }

    }
}

</script>

<style lang="css">

.message-board {
    bottom: 0px;
    transition: all .3s ease-in-out;
    overflow: hidden;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.feed-container {
    background: #ffffff;
    overflow: hidden;
    height: 100%;
}

.feed {
    position: relative;
    flex: 1;
    height: 200vh;
    flex-direction: column;
    padding: 30px;
}

.toolbar {
    margin: 10px 0px;
}

.search-label {
    /* font-weight: 600; */
    margin-left: .357rem;
    margin-bottom: 10px;
    display: block;
}

.t-btn {
    width: calc(50% - 10px);
    padding:8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    border: 1px solid #ffffff;
    border-radius: 0px;
    background: black;
    transition: all 0.1s ease-in-out;
    color: white;
    font-weight: 500;
}

.t-btn.t-write {
    margin-right: 10px;
}

.t-btn.t-find {
    margin-left: 10px;
}

.t-btn > * {
    transition: all 0.1s ease-in-out;
}

.t-btn:hover, .t-btn:focus {
    color: white;
    text-decoration: none;
    opacity: 0.8;
}

.t-btn span {
    margin-left: 8px;
}

/* inline form styles */

.t-submit {
    background: #fc0054;
    padding: 10px 40px;
    border-radius: 0px;
    color: black;
    font-weight: 600;
    float: right;
    transition: all 0.2s ease-in-out;
}

.t-submit:hover {
    opacity: 0.7;
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.t-btn.t-search {
    border: none;
    transition: all 0.2s ease-out;
}

.t-btn.t-search:hover {
    opacity: 0.8
}

.message-search {
    border-radius: 0px;
    border-color: #fc0054;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    background: rgba(255,255,255,0);
    padding: .357rem 0rem;
    transition: all 0.2s ease-in-out;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 10%);
    margin: 1rem 0rem;
}

.message-search svg {
    stroke: #fc0054;
}

.message-search::placeholder, .message-search:focus {
    border-color: #fc0054;
    color: #ffffff;
    padding: .357rem .357rem;
}

.message-search:focus {
    transform: scaleX(1.05);
    padding: .357rem .357rem;
    background: rgba(255,255,255,0.1);
    border-width: 0px 0px 2px 0px;
}

/* Modal Styles */

#message-modal, #find-modal {
    color: #ffffff;
}

.modal-backdrop {
    background-color: #000000;
    opacity: 0.95;
}

#message-modal .modal-header,
#find-modal .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
}

#message-modal .modal-header .close,
#find-modal .modal-header .close {
    opacity: 1;
    transition: all 0.2s ease-in-out;
    color: #ffffff;
    text-shadow: none;
}

#message-modal .modal-header .close:hover,
#find-modal .modal-header .close:hover {
    color: #fc0054;
}

#message-modal .modal-header .modal-title,
#find-modal .modal-header .modal-title {
    font-weight: 600;
}

#message-modal .modal-content, #find-modal .modal-content {
    background: none;
    border: none;
}

/* Instructions Modal Styles */

.intructions-row {
    margin-bottom: 15px;
}

.instructions-line {
    margin-left: 30px;
}

@media screen and (max-width: 991px) {

    .message-board {
        overflow: unset;
        padding: 70px 20px 10px 20px;
    }
    
    .t-btn {
        width: 100%;
        max-width: none;
        background-color: black;
        color: white;
        font-weight: 500;
    }

    .t-btn:focus {
        color: white;
    }

    .t-btn.t-write {
        padding: 10px;
    }

}

</style>
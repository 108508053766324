<template>
    <div class="comment-container">
        <span class="comment-message">"{{ message }}"</span>
        <div>
            <span class="comment-name">{{ name }} - </span>
            <span class="comment-country">{{ country }}</span>
            <!-- <span class="comment-time">{{ time }}</span> -->
        </div>
    </div>
</template>

<script>

export default {
    name: "FeedComment",
    props: {
        name: String,
        message: String,
        country: String,
        time: String,
    }
}

</script>

<style lang="css">

.comment-container {
    height: auto;
    display: block;
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
    text-align: left;
    float: left;
}

.comment-container.active {
    /* background-color: rgba(255,255,255,0.3); */
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    padding: 20px 10px;
}

.comment-name {
    /* font-weight: 600; */
}

.comment-message {
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
}

.comment-country {
    display: inline-block;
    /* font-weight: 600; */
    margin-right: .6rem;
}

</style>
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyByjmwkTgL-UmIaLvjmn3bLyaug7ndm5gI",
  authDomain: "chanelle-rose-experience.firebaseapp.com",
  projectId: "chanelle-rose-experience",
  storageBucket: "chanelle-rose-experience.appspot.com",
  messagingSenderId: "622426829508",
  appId: "1:622426829508:web:4065fe32ddde7b19810f91",
  measurementId: "G-4Y3E5GPRLE"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Export Firebase Analytics app and Databse Connection
export const firebaseAnalytics = firebase.analytics();
export const db = firebaseApp.firestore();

import { db } from '../../firebase.js';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export async function createComment(comment) {

    const docRef = db.collection('test_artwork');

    var dateOBJ = new Date();
    var cTime = (monthNames[dateOBJ.getUTCMonth() + 1]) + ", " + dateOBJ.getUTCFullYear();
    
    return docRef.add({
      name: comment.name,
      message: comment.message,
      aID: comment.aID,
      country: comment.country,
      time: cTime,
      date: dateOBJ
    }).catch(function(error) {
      return error;
    });

}

export async function getMessages() {

  try {

    const docRef = db.collection('test_artwork').orderBy('date').limitToLast(15);
    const snapshot = await docRef.get();

    if(snapshot.empty) {
      return [];
    }

    let docs = [];

    snapshot.forEach(doc => {
      
      let obj = {
        id: doc.id, 
        data: doc.data(),
        active: false
      };

      docs.push(obj);
    })

    let rdata = docs.reverse();

    return rdata;


  } catch(e) {

    console.log(e);
    return(e);

  }

}

export async function getMessage(id) {

  try {

    const docRef = db.collection('test_artwork').doc(id);
    const doc = await docRef.get();
    let data = doc.data();
    console.log(data);
    if(!doc.exists) {
      return;
    }
    else {
      let obj = {
        id: doc.id, 
        data: doc.data(),
        active: true
      };
      return obj;
    }

  }

  catch(e) {

    console.log(e);
    return;

  }

}

import * as THREE from 'three';
import { TextureLoader } from 'three';
// import anime from 'animejs/lib/anime.es.js';
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from '../engine/OrbitControls';

// Instance Variables //
var scene;
var camera;
var renderer;
var controls;

// Canvas Settings //
var canvas;

// Loaders
var textureLoader = new TextureLoader();

// Camera Settings
var fov = 10;
var near = 0.1;
var far = 2000.00;

// Controls
const zoomMax = 40;
const zoomMin = 2;
const azimuthMax = .6;
const azimuthMin = -.6;
const minPolar = 1;
const maxPolar = 2;

// Lights
var hemisphereLight;
var directionalLight;

// Geometry Settings
var aspect;
var primaryObj;

////////////////////
//-- Create Canavs - Sloppy Init for Dev Purposes
////////////////////
export async function canvasInit(container) {
  try {

    //-- Initiate First Scene
    scene = new THREE.Scene();

    //-- Set container ref
    canvas = container;

    //-- Create Camera
    camera = new THREE.PerspectiveCamera( fov, canvas.clientWidth / canvas.clientHeight, near, far );

    //-- Create Renderer and Append to Container
    renderer = new THREE.WebGLRenderer({ alpha: true, antialiased: true });
    renderer.setSize( canvas.clientWidth, canvas.clientHeight );
    renderer.setClearColor( 0x000000, 0 );
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setPixelRatio(window.devicePixelRatio);
    container.appendChild( renderer.domElement );

    //-- Initialize Orbit Controls
    controls = new OrbitControls( camera, renderer.domElement );
    controls.minDistance = zoomMin;
    controls.maxDistance = zoomMax;
    controls.distance = 50;
    controls.enableDamping = true;
    controls.enableRotate = false;
    controls.minAzimuthAngle = azimuthMin;
    controls.maxAzimuthAngle = azimuthMax;
    controls.minPolarAngle = minPolar;
    controls.maxPolarAngle = maxPolar;

    // Create Object Geometry for Art
    aspect = await getMeta('/KABOHS00HWE5W6Z49E93.jpg');
    primaryObj = await createPrimaryGeometry(aspect, '/KABOHS00HWE5W6Z49E93.jpg');
    scene.add(primaryObj);

    //-- Initialize Lights
    hemisphereLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 1 );
    directionalLight = new THREE.DirectionalLight( 0x404040 ); // soft white light
    directionalLight.position.set(10,10,10);
    scene.add( hemisphereLight );
    scene.add( directionalLight );

    camera.position.z = 20 / camera.aspect;

    window.addEventListener('resize', onWindowResize, false );

    animate();

    return (true);

  } catch(e) {

    console.log(e);
    return(e);

  }
}

////////////////////
// Constant Animate Function
////////////////////
const animate = function () {
  requestAnimationFrame( animate );
  controls.update();
  renderer.render( scene, camera );
};

////////////////////
// Window Resized
////////////////////
const onWindowResize = function(){

    // Change camera aspect ratio
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
    // Set new renderer dimensions
    renderer.setSize( canvas.clientWidth, canvas.clientHeight );

}

//
// Temp Helpers

// Get Meta
async function getMeta(url) {
  var img = new Image();
  img.src = url;
  await img.decode();
  // img is ready to use
  return {
    ratio: img.width / img.height, 
    orientation: img.width > img.height
  };
}

// Create Primary Geometry
async function createPrimaryGeometry(aspect, url) {

  // Create a texture from 
  let texture = await textureLoader.load( url );
  texture.generateMipmaps = false;
  texture.encoding = THREE.sRGBEncoding;
  texture.magFilter = THREE.LinearFilter;
  texture.minFilter = THREE.LinearFilter;
  // texture.color.convertSRGBToLinear();
  texture.needsUpdate = true;

  // immediately use the texture for material creation
  let materials = [
    new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0} ),
    new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0} ),
    new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0} ),
    new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0} ),
    new THREE.MeshBasicMaterial( { map: texture, dithering: true } ),
    new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0} ),
  ]

  let geoSize = {
    x: aspect.orientation ? ((1 * aspect.ratio) * 1.5) : ( 1.5 ),
    y: !aspect.orientation ? ((1 * aspect.ratio) * 1.5) : ( 1.5 )
  }

  let geometry = new THREE.BoxGeometry(geoSize.x, geoSize.y, .2);

  let art = new THREE.Mesh( geometry, materials );

  art.position.set(0,0,0);
  
  return art;
}
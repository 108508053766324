<template>
  <b-overlay
      id="overlay-background"
      :show="show"
      opacity="1"
      rounded="sm"
      variant="dark"
    >
    <div class="experience">
      <b-row class="experience-row">
        <b-col lg="8" md="12" id="experience-col-1" align-self="center">
          <h1 class="title">MEETING OF<br>THE MINDS</h1>
          <div class="engine" ref="engine">
            <img v-if="mobile" class="mobile-art" src="/KABOHS00HWE5W6Z49E93.jpg"/>
          </div>
          <span class="art-caption">Chanelle Rose, Meeting of the Minds, 2021. Ballpoint Ink on Paper, 1830mmW x 800mmH. © Chanelle Rose.</span>
          <div style="padding: 15px;">
            <p><strong>A CALL TO YOU;</strong><p>
            <p>The world is transitioning, we have a chance to make it great.</p>
            <p><strong>What is something you think needs more discussion, and is being left behind, that is really important to you?</strong></p>
            <p>The scroll in the artwork is left blank for you and your thoughts<br>
            <strong>What would you like to bring to the table?</strong></p>
          </div>
        </b-col>
        <b-col lg="4" md="12" class="experience-col">
          <MessageBoard ref="messageBoard"/>
        </b-col>
      </b-row>
    </div>
    <b-modal id="intro-modal" ref="introModal" size="lg" centered hide-footer>
      <p><strong>'Meeting of the Minds'</strong> considers the issues that are being overlooked throughout our world, during this incredibly transitional time.</p>
      <p>Many people are working to shift from where we were, to where we need to be. Let’s keep the conversations going, the shifts happening and moving forward - together.</p>
      <p>Where do we go from here? I call on you to create awareness, to inspire thought, conversation and insight for what matters to you. </p>
      <p><strong>Conversation alone can be a powerful mover.</strong></p>
    </b-modal>
  </b-overlay>
</template>

<script>

import { canvasInit } from '@/assets/js/engine.js';
import MessageBoard from '@/components/Experience/MessageBoard.vue'

export default {
  name: 'Experience',
  components: {
    MessageBoard
  },
  data() {
    return {
      show: true,
      mobile: false
    }
  },
  mounted() {
    this.$nextTick(async function () {
      this.$refs.introModal.show();
      if(window.innerWidth < 767) {
        this.mobile = true;
        this.show = false;
      }
      else {
        await canvasInit(this.$refs.engine);
        this.show = false;
      }
    });
  },
  methods: {
    toggleModal: function() {
      this.$refs.messageBoard.toggleModal(0);
    }
  }
}

</script>

<style lang="css">

  .experience {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    background: rgb(0,0,0);
    height: 100vh;
  }
  .experience.loading {
    overflow: hidden;
    max-height: 100vh;
  }

  .mobile-art {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  .b-overlay .bg-dark {
    background-color: black!important;
  }

  .b-overlay .spinner-border {
    color: #fc0054;
  }

  .experience-row {
    min-height: 100vh;
  }

  .experience-col {
    height: 100vh;
  }

  #experience-col-1 {
    padding-top: 50px;
    padding-right: 0px;
  }

  #experience-col-1 p {
    color: #fc0054;
    display: block;
    line-height: 1.1rem;
    max-width: 600px;
    margin: .6rem auto;
  }

  .title {
    color: #fc0054;
    font-size: 60px;
    line-height: 38px;
    text-align: center;
    font-weight: 900;
    padding: .2rem .357rem .2rem .357rem;
  }

  .art-caption {
    color: #6c6c6c;
    font-size: 12px;
    float: left;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .engine {
    height: 50vh;
    width: 100%;
    z-index: 0;
    cursor: grab;
  }

  .comment-sidebar {
    text-align: left;
  }

  .experience-welcome {
    padding: 15px 30px;
    color: #000000;
    margin: auto;
    margin-top: .8rem;
    margin-bottom: .8rem;
    background-color: #fc0054;
    display: inline-block;
    width: auto;
    font-weight: 900;
    border: none;
    border-radius: 0px;
    transition: all 0.2s ease-in-out;
  }

  .experience-welcome:hover {
    opacity: .7;
    background-color: #fc0054;
    color: #000000;
  }

  .experience-welcome:focus {
    background-color: #fc0054;
    color: #000000;
  }

  /* Modal Styles */

  #intro-modal, #find-modal {
      color: #ffffff;
  }

  .modal-backdrop {
      background-color: #000000;
      opacity: 0.95;
  }

  #intro-modal .modal-content {
    background: none;
    border:none;
    font-weight: 400;
  }

  #intro-modal .modal-content strong {
    color: #fc0054;
    font-weight: 500;
  }

  #intro-modal .modal-content p {
    margin-bottom: 1.5rem;
  }

  #intro-modal .modal-header{
      border-bottom: none;
      padding-bottom: 0px;
      color: #fc0054;
  }

  #intro-modal .modal-header .close{
      opacity: 1;
      transition: all 0.2s ease-in-out;
      color: #ffffff;
      text-shadow: none;
  }

  #intro-modal .modal-header .close:hover{
      color: #fc0054;
  }

  #intro-modal .modal-header .modal-title{
      font-weight: 600;
  }

  @media screen and (max-width: 991px) {
    
    #experience-col-1 {
      margin-top: 75px;
      padding-right: 15px;
      align-self: unset;
    }

    .engine {
      height: auto;
      margin: 30px 0px;
    }

    .t-btn.t-write {
      margin-right: 0px;
      margin-bottom: 10px;
    }

    .t-btn.t-find {
      margin-left: 0px;
    }

  }

</style>

<template>
    <b-row>
        <transition name="fade">
            <b-form id="message-form" @submit="submitComment" v-if="!submitted">
                <span class="confirm-heading">What would you like to bring to the table?</span>
                <b-form-group
                id="message-name"
                label-for="message"
                description="Does not need to be your real name."
                class="mb-4"
                :state="comment.name >= 2"
                >
                    <b-form-input id="name" required v-model="comment.name" class="message-input" placeholder="Enter your name" trim></b-form-input>
                </b-form-group>
                <b-form-group
                id="message-name"
                label-for="message"
                description="Max. 900 characters"
                class="mb-4"
                >
                    <b-form-textarea 
                        id="message" 
                        required 
                        v-model="comment.message" 
                        class="message-input" 
                        placeholder="Enter your message" 
                        trim
                        max-rows="2"
                        :state="comment.message >= 10"
                        no-resize
                    >
                    </b-form-textarea>
                </b-form-group>
                <b-form-group
                id="country"
                label-for="name"
                description="Where does this message come from?"
                class="mb-4"
                >
                    <b-form-select
                        id="country"
                        v-model="comment.country"
                        class="message-input"
                        :options="countries"
                        value-field="name"
                        text-field="name"
                    />
                </b-form-group>
                <div style="text-align: right">
                    <b-button variant="link" type="submit" inline class="m-btn submit">Submit</b-button>
                </div>
            </b-form>
        </transition>
        <transition name="fade">
            <div>
                <div class="confirmation" v-if="submitted">
                    <span class="confirm-heading">Thank-you for attending The Meeting of The Minds and bringing your thoughts to the table.</span>
                    <p>
                        I am sure your words will inspire many and provoke conversation, insight and awareness to important issues needing attention.
                    </p>
                    <p>
                        I am grateful for your words and humbled that you took the time to voice your thoughts.
                    </p>
                    <p>
                        Please feel free to share your words with me (screenshot or post your words, tag me or dm me), 
so I can share your great insights with others @chanelle_rose
                    </p>
                    <p class="message-end">Chanelle XO</p>
                    <p>Use the ID below in the search bar to see your comments again:</p>
                </div>
                <div class="confirmation" v-if="submitted">
                    <span class="confirm-id">Message ID: <strong>{{this.commentId}}</strong></span>
                </div>
            </div>
        </transition>
    </b-row>
</template>

<script>

import { createComment } from '@/assets/js/database.js';
import { defaultCountries } from '@/data/countries.js';
import Filter from 'bad-words';

export default {
    name: 'SubmitMessage',
    props: {
        aID: Number,
    },
    data() {
        return {
            submitted: false,
            documentID: null,
            comment: {
                name: null,
                message: null,
                aID: 0,
                country: "Australia",
            },
            commentId: null,
            countries: defaultCountries,
            errorMsg: null
        }
    },
    methods: {
        submitComment: async function(event) {
            event.preventDefault()
            let filter = new Filter();
            filter.removeWords('sex', 'sexual', 'Hell', 'Bollocks', 'breasts', 'damn', 'screwing', 'shit', 'shitty', 'sluts', 'slut', 'balls', 'lusting', 'pissed');
            if(filter.isProfane(this.comment.message) || filter.isProfane(this.comment.name)) {
                alert('Message or name must not contain profanity or hate speech')
            }
            else if(this.comment.message.length > 900) {
                alert('Please limit your message to 900 characters');
            }
            else {
                let cm = await createComment(this.comment);
                this.commentId = cm.id;
                this.submitted = true;
            }
        },
    }
}

</script>

<style lang="css">

/* General Styles */

.form-heading {
    font-weight: 500;
    font-size: 1rem;
    display: block;
}

/* Button Styles */

.m-btn {
    transition: all 0.2s ease-in-out;
    font-weight: 600;
}

.m-btn.cancel {
    color: #4c4c4c;
}

.m-btn.submit {
    color: #000000;
    background-color: #fc0054;
    border-radius: 0px;
    padding: 5px 30px;
    text-transform: uppercase;
}

.m-btn:hover, .m-btn.submit:hover {
    opacity: 0.8;
    text-decoration: none;
}

/* Input Styles */

#message-form {
    max-width: 650px;
    width: 100%;
}

#message-form .form-text {
    color: #9c9c9c!important;
}

.message-input {
    border-radius: 0px;
    border-color: #fc0054;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    background: rgba(0,0,0,0);
    padding: .357rem 0;
    transition: all 0.2s ease-in-out;
    color: #ffffff;
}

.message-input.custom-select {
    color: #ffffff;
}

.message-input.custom-select option {
    color: #000000;
}

.message-input svg {
    stroke: #fc0054;
}

.message-input:focus {
    border-color: #fc0054;
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 10%);
    background: rgba(255,255,255,0.1);
    color: #ffffff;
}

#name:focus, #message:focus {
    transform: scaleX(1.05);
    padding: .357rem .357rem;
}

.message-input::placeholder {
    color: #ffffff;
}

.message-input.is-invalid, .form-control.is-invalid:focus {
    border-color: #fc0054;
}

.form-control.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 10%);
}

/* Confirm Message */
.confirm-heading {
    margin-bottom: 1.5rem;;
    display: block;
    color: #fc0054;
    font-size: 1.2em;
}

.confirm-id {
    font-size: 1.1em;
    font-weight: 600;
}

.confirm-id strong {
    color: #fc0054;
    font-weight: 500;
}

.confirmation p {
    margin-bottom: 1.2rem;
}

.confirmation .message-end {
    margin-bottom: 2.5rem;
}

</style>